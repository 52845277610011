import { useEffect, useMemo, useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { FaTimes } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { GameContainer } from 'components/GameContainer';

import { useBasic } from 'contexts';

// import { gamesList, gamesNarrativeList } from '../Games';

import './style.scss';
import { api, getDetailContent } from 'services';

export default function GamesDetailPage() {
  const { setShowHeader, appearance } = useBasic();
  const { gameType, gameID } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [gameData, setGameData] = useState<any>();

  // const gameData = useMemo(() => {
  // 	if (!gameID) return null;

  // 	const gameObj: any = null;

  // 	// const gameObj = [...gamesList, ...gamesNarrativeList].find(game => game.id === gameID);

  // 	if (!gameObj) return null;

  // 	return gameObj;
  // }, [gameID]);

  const previousUrl = useMemo(() => {
    const url = location.state?.previous || '/games';
    return url;
  }, [location]);

  useEffect(() => {
    const loadData = async () => {
      if (!gameID || !gameType) return;

      const { data } = await api.post(`find/${gameType}`, {
        contentId: gameID,
      });

      console.log(data);

      if (data && data.length > 0) {
        setGameData(data[0]);
      }
    };

    loadData();
  }, [gameType, gameID]);

  useEffect(() => {
    if (appearance?.navigation?.displayMenu) {
      setShowHeader(false);
    }

    return () => setShowHeader(true);
  }, [setShowHeader, appearance]);

  if (!gameID || !gameType) return null;

  return (
    <div id="games-detail" className="flex flex-1 flex-col h-full gap-4">
      <div
        className={`head block lg:flex justify-between w-full ${
          !!gameData?.description?.length && !!gameData?.howToPlay?.length ? 'items-start' : 'items-center'
        }`}
      >
        <div className={'crumbs has-btn-back text-left'}>
          {!!gameData && (
            <p>
              <strong>{gameData.title}</strong>
              {!!gameData.description?.length && !!gameData.howToPlay?.length && (
                <span className="title">{gameData.description}</span>
              )}
            </p>
          )}
        </div>

        {!appearance?.navigation || appearance?.navigation?.displayMenu ? (
          <Link to={previousUrl} className="back">
            Fechar
            <span className="ico ml-1">
              <FaTimes />
            </span>
          </Link>
        ) : (
          <div />
        )}
      </div>

      {/* <h3>{gameData?.title}</h3> */}

      <div>{gameData?.description}</div>

      <GameContainer id={gameType} contentId={gameID} />

      {/* {(!!gameData?.howToPlay?.length || !!gameData?.description?.length) && (
        <div>
          <h5 className="font-bold">Como Jogar:</h5>
          <p>{gameData.howToPlay || gameData.description || ''}</p>
        </div>
      )} */}
    </div>
  );
}
